import get_title from "@/router/module/get_title";
import products from "./products";
import settings from "./settings";
import categories from "./categories";
import images from "./images";
import Store from "@/store";

export default {
  path: "/content/catalog/:modelId",
  name: "model-edit--spa",
  redirect: (to) => {
    const role = Store.getters["auth/role"];
    if (role.slug === "photographer") {
      return `/content/catalog/${to.params.modelId}/images`;
    }
    return `/content/catalog/${to.params.modelId}/settings`;
  },
  meta: {
    title: get_title("Модель"),
    forVisitors: true,
  },
  beforeEnter: (to, from, next) => {
    if (to && to.query && to.query.modelId) {
      return next(`/content/catalog/${to.query.modelId}/settings`);
    }
    return next();
  },
  component: () => import("@/views/Content/Catalog.vue"),
  children: [products, settings, categories, images],
};
