import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";
import get_title from "@/router/module/get_title";

export default {
  path: "/content/catalog/:modelId/settings",
  name: "model-edit__settings",
  meta: {
    title: get_title("Модель | Основная информация"),
    forVisitors: true,
  },
  component: () => import("@/new/pages/catalog/edit/settings.vue"),
  beforeEnter: NomenclatureModelRouterHook("model.show-general"),
};
