const state = () => ({
  per_page: 10,
  needToUpdate: false
});

const mutations = {
  setPerPage(state, v) {
    state.per_page = v;
  },
  setNeedToUpdate(state, v) {
    state.needToUpdate = v;
  }
};

const getters = {
  needToUpdate: (state) => state.needToUpdate,
};

export default {
  namespaced: true,
  getters,
  mutations,
  state,
};
